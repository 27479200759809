.auth-container {
	display: flex;
	.card-content {
		flex: 40%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px;
		.card-container {
			width: 100%;
			max-width: 521px;
			.card-header {
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: 50px;
				margin-bottom: 16px;
				.card-header-title {
					font-weight: 700;
					font-size: 24px;
					line-height: 36px;
					text-align: center;
					color: #444444;
				}
			}
			.card-body {
				form {
					display: flex;
					flex-direction: column;
					row-gap: 30px;
					.form-group {
						position: relative;
					}
					.form-control {
						width: 100%;
						background: #ffffff;
						border: 1px solid #b6ebf4;
						border-radius: 20px;
						font-size: 16px;
						line-height: 24px;
						color: #a0a0a0;
						padding: 8px 24px;
					}
					.alert {
						position: absolute;
						top: 42px;
						right: 0px;
						left: 0;
						color: aliceblue;
						padding: 5px 20px;
						border-radius: 10px;

						&.alert-success {
							background: #06d6a0;
						}
						&.alert-danger {
							background: #ef476f;
						}
					}
				}
			}
		}
	}
	.card-image {
		flex: 50%;
		img {
			max-width: 100%;
			height: 100%;
			max-height: 901px;
		}
	}
}
