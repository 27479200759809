.detail {
	display: flex;
	column-gap: 30px;
	.single {
		max-width: 521px;
		width: 100%;
	}
	.calculation {
		width: 100%;
	}
}
