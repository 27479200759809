.search-input {
	background: #ffffff;
	border: 1px solid #b6ebf4;
	border-radius: 20px;
	max-width: 802px;
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	padding: 0 24px;

	input {
		width: 100%;
	}
}
