@import url("./assets/fonts/stylesheet.scss");

* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
	box-sizing: border-box;
}
#root {
	height: 100vh;
}
body {
	line-height: 1;
	font-family: "Raleway";
	font-weight: 400;
	background: #f5f5f5;
	min-height: 100vh;
}
ol,
ul {
	list-style: none;
}
button {
	border: none;
	outline: 0;
	cursor: pointer;
	background: none;
}
a {
	text-decoration: none;
}
input:active,
input:focus {
	border: none;
	outline: 0;
}
