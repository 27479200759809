.layout {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}
.container {
	max-width: 1654px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;
}

header {
	margin-top: 24px;
	background: #ffffff;
	box-shadow: 0px -4px 20px rgba(225, 247, 251, 0.5),
		0px 4px 20px rgba(225, 247, 251, 0.5);
	border-radius: 44px;
}
main {
	padding: 40px 0 120px;
	flex: 1;
	height: 100%;
}

footer {
	background: #ffffff;
	box-shadow: 0px -4px 20px rgba(225, 247, 251, 0.5),
		0px 4px 20px rgba(225, 247, 251, 0.5);
	border-radius: 24px 24px 0px 0px;
	margin-bottom: 24px;
}
