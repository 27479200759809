.filter {
	.search-row {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 32px;
	}
	.col {
		width: calc(
			(100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
		);
	}
}

.custom--select {
	.custom--select-prefix__control {
		background: #ffffff;
		border: 1px solid #b6ebf4;
		border-radius: 20px;
		box-shadow: none;
		&:hover {
			border: 1px solid #b6ebf4;
		}
		&.custom--select-prefix__control--is-focused {
			border: 1px solid #b6ebf4;
			// border-radius: 20px 20px 0px 0px;
		}
		&.custom--select-prefix__control--menu-is-open {
			border: 1px solid #b6ebf4;
			border-radius: 20px 20px 0px 0px;
		}
		.custom--select-prefix__indicator-separator {
			display: none;
		}
	}
	.custom--select-prefix__menu {
		background: transparent;
		margin: 0;
		padding: 0;
		box-shadow: none;
		.custom--select-prefix__menu-list {
			border-radius: 0px 0px 20px 20px;
			border: 1px solid #b6ebf4;
			padding: 0;
			.custom--select-prefix__option {
				background: #ffffff;
				border-bottom: 1px solid #b6ebf4;
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				color: #444444;
				cursor: pointer;
				transition: all 0.25s ease-out;
				&:hover {
					font-weight: bold;
				}
				&:last-child {
					border-radius: 0px 0px 20px 20px;
				}
			}
		}
	}
}
