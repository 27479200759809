.calc-input {
	display: flex;
	align-items: center;
	column-gap: 2px;
	background: #ffffff;
	border: 1px solid #b6ebf4;
	border-radius: 20px;
	padding: 8px 16px;
	max-width: fit-content;
	input.calc--input {
		width: 100% !important;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #444444;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}
}
