.dropdown {
	position: relative;
	display: flex;
	align-items: center;
	height: 100%;
	.dropdown--btn {
		display: flex;
		align-items: center;
		column-gap: 12px;
		font-size: 16px;
		line-height: 24px;
		color: #3aabc2;
	}
	.dropdown--menu {
		position: absolute;
		top: calc(100% + 16px);
		left: 50%;
		transform: translateX(-60%);
		background: #ffffff;
		box-shadow: 0px -4px 20px rgba(225, 247, 251, 0.5),
			0px 4px 20px rgba(225, 247, 251, 0.5);
		border-radius: 20px;
		width: 246px;
		overflow: hidden;
		display: none;
		&.show {
			display: block;
		}
		.dropdown--item {
			font-size: 16px;
			line-height: 24px;
			color: #444444;
			width: 100%;
			height: 56px;
			display: flex;
			align-items: center;
			column-gap: 12px;
			padding-left: 20px;
			border-bottom: 1px solid #b6ebf4;
			transition: all 0.25s ease-in;
			cursor: pointer;
			&:last-child {
				border-bottom: none;
			}
			&:hover {
				background: #ecf7f9;
			}
		}
	}
}
