.header--row {
	height: 88px;
	padding: 0 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.header--favourites {
		a {
			display: flex;
			align-items: center;
			column-gap: 8px;
			font-size: 18px;
			line-height: 27px;
			color: #444444;
		}
	}
	.header--btn {
		display: flex;
		align-items: center;
		column-gap: 24px;
	}
}
