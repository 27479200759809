.card {
  .heart {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
  width: calc(
    (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
  );

  &--img {
    width: 100%;
    height: 100%;
    max-height: 244px;
    overflow: hidden;
    border-radius: 12px;

    img {
      width: 100%;
      height: 100%;
      transition: all 0.25ms ease-in;
    }
    // &:hover {
    // 	img {
    // 		transform: scale(1.2);
    // 	}
    // }
  }
  &--body {
    position: relative;
    margin-top: 13px;

    .card--title {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #444444;
    }
    .card--details {
      margin: 4px 0 8px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      small {
        font-size: 16px;
        line-height: 24px;
        color: #777777;
      }
      i {
        display: block;
        width: 1px;
        height: 14px;
        background: #777777;
      }
    }
    address {
      display: flex;
      column-gap: 12px;
      button {
        text-align: left;
        font-size: 16px;
        line-height: 24px;
        color: #3d4f66;
      }
    }
  }
}
