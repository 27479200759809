.calc {
	display: flex;
	flex-direction: column;
	.rows {
		--gap: 0px;
		display: flex;
		max-width: 100%;
		width: 100%;
		flex-wrap: wrap;
		gap: var(--gap);
		--columns: 4;
	}
	.col {
		color: #444444;
		width: calc(
			(100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
		);
	}
	.calc-header {
		min-height: 59px;
		width: 100%;
		background: #b0dde7;
		border-radius: 20px 20px 0px 0px;
		display: flex;
		align-items: center;
		.col {
			text-align: center;
			font-weight: 700;
			font-size: 18px;
			line-height: 27px;
		}
	}
	.calc-body {
		.rows {
			background: white;
			&:nth-child(odd) {
				background: #ecf7f9;
			}
		}
		.col {
			min-height: 56px;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			padding: 0 20px;
			border: 1px solid #b6ebf4;
		}
		.calc-btn {
			max-width: 100%;
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #b6ebf4;
		}
	}
}
