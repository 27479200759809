.footer--row {
	height: 56px;
	padding: 0 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.footer-description {
		font-size: 16px;
		line-height: 24px;
		color: #444444;
	}
	.footer-law {
		display: flex;
		align-items: center;
		column-gap: 16px;
		a {
			font-size: 16px;
			line-height: 24px;
			color: #444444;
		}
		.footer-line {
			display: block;
			width: 1px;
			height: 14px;
			background: #444444;
		}
	}
}
