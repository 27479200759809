.header {
	margin-bottom: 30px;
	&--title {
		font-weight: 700;
		font-size: 48px;
		line-height: 56px;
		text-align: center;
		color: #444444;
		margin-bottom: 24px;
	}
}
