.btn {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #3d4f66;
	padding: 8px 24px;
	width: fit-content;
	border-radius: 24px;
	cursor: pointer;

	&.btn-full {
		color: #ffffff;
		background: linear-gradient(97.79deg, #3d4f66 -0.55%, #3aabc2 102.67%);
	}
	&.btn-outline {
		background: #ffffff;
		border: 1px solid #3d4f66;
	}
	&.btn-white {
		background: #ffffff;
	}
	&.w-100 {
		width: 100%;
	}
}
